<template>
  <div class="txt-black txt-16">
    <p>
      {{ $t('components.notifications.languagesProvidedInTheFileDoNotMatchLanguagesSelected') }}
    </p>
    <ul class="pl-20">
      <li v-for="lang in languages" :key="lang" class="txt-font-din-regular">{{ lang }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    languages: {
      type: Array,
      default: () => []
    }
  }
};
</script>
